<template>
  <validation-observer ref="employeeForm" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit()" autocomplete="off" novalidate>
      <b-container>
        <b-row>
          <b-col>
            <h6>Información básica</h6>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              vid="first-name"
              name="nombre"
              rules="required|max:255"
              v-slot="validationContext"
            >
              <b-form-group id="first-name" label-for="input-first-name">
                <b-form-input
                  id="input-first-name"
                  name="input-first-name"
                  :state="getValidationState(validationContext)"
                  placeholder="Nombre"
                  v-model="form.firstName"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              vid="lastname"
              name="apellido"
              rules="required|max:255"
              v-slot="validationContext"
            >
              <b-form-group id="input-lastname" label-for="input-lastname">
                <b-form-input
                  id="input-lastname"
                  name="input-lastname"
                  :state="getValidationState(validationContext)"
                  placeholder="Apellido"
                  v-model="form.lastName"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              vid="nickname"
              name="Nick / nombre artístico"
              v-slot="validationContext"
            >
              <b-form-group id="nickname" label-for="input-nickname">
                <b-form-input
                  id="input-nickname"
                  name="input-nickname"
                  v-model="form.technician.nickname"
                  :state="getValidationState(validationContext)"
                  placeholder="Nick / nombre artístico"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h6>Datos de contacto</h6>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <validation-provider
              vid="linkedInProfile"
              name="perfil linkedin"
              :rules="{ regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/ }"
              v-slot="validationContext"
            >
              <b-form-group id="linkedin" label-for="input-linkedin">
                <b-form-input
                  id="input-linkedin"
                  name="input-linkedin"
                  :state="getValidationState(validationContext)"
                  placeholder="Perfil Linkedin (URL)"
                  v-model="form.technician.linkedInProfile"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="2">
            <validation-provider
              name="prefijo"
              :rules="{ prefix: true }"
              v-slot="validationContext"
            >
              <b-form-group id="phone-prefix" label-for="input-phone-prefix">
                <b-form-input
                  id="input-phone-prefix"
                  maxlength="4"
                  name="input-phone-prefix"
                  v-model="form.technician.phoneNumber.prefix"
                  :state="getValidationState(validationContext)"
                  placeholder="Prefijo"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="4">
            <validation-provider
              name="phone-number"
              rules="max:255"
              v-slot="validationContext"
            >
              <b-form-group id="phone-number" label-for="input-phone-number">
                <b-form-input
                  id="input-phone-number"
                  name="input-phone-number"
                  :state="getValidationState(validationContext)"
                  placeholder="Número de teléfono"
                  v-model="form.technician.phoneNumber.number"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h6>Dirección</h6>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="8">
            <GooglePlaces
              :value="value"
              @selected="onChangeGoogleData"
              @keypress="onKeyPressGoogle"
            />
          </b-col>
          <b-col cols="4">
            <b-form-group id="postal-code" label-for="input-postal-code">
              <b-form-input
                id="input-postal-code"
                type="number"
                name="input-postal-code"
                v-model="form.technician.postalCode"
                placeholder="Código postal"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider
              vid="address"
              name="dirección"
              v-slot="validationContext"
            >
              <b-form-group id="address" label-for="input-address">
                <b-form-input
                  id="input-address"
                  name="input-address"
                  v-model="form.technician.address"
                  placeholder="Dirección (Calle y número)"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </validation-observer>
</template>

<script>
import GooglePlaces from '../../Places/GooglePlaces.vue'
import LocationFactory from '@/domain/services/LocationFactory'

export default {
  name: 'EmployeeForm',
  components: {
    GooglePlaces
  },
  props: {
    form_prop: Object
  },
  data: function () {
    return {
      form: this.form_prop,
      value: null
    }
  },
  created () {
    this.setGoogleValue()
  },
  methods: {
    onChangeGoogleData (evt) {
      this.form.technician.location = LocationFactory.getLocationByGoogle(evt)
      this.setGoogleValue()
    },
    setGoogleValue () {
      if (this.form.technician.location.name) {
        this.value = this.form.technician.location.name + ', ' +
          this.form.technician.location.administrative + ', ' +
          this.form.technician.location.country
      } else {
        this.value = ''
      }
    },
    onKeyPressGoogle () {
      this.value = ''
    }
  }
}
</script>

<style lang="less" scoped>
@import "employeeForm";
</style>
